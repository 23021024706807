import React from "react";

import { Flex, Text, Image } from "@chakra-ui/react";

import { BASE_URL_NEWS_IMAGE } from "../../../utils/config";

function ItemList({ item, onClick = () => {}, ...rest }) {
  return (
    <Flex
      maxW="100%"
      p="10px"
      bg="white"
      borderRadius="5px"
      boxShadow="md"
      _hover={{
        boxShadow: "lg",
      }}
      h={["auto", "140px"]}
      cursor="pointer"
      flexDir={["column", "row"]}
      onClick={onClick}
      {...rest}
    >
      <Image
        src={`${BASE_URL_NEWS_IMAGE}${item?.news_image}`}
        w={["100%", "120px"]}
        h={["140px", "85px"]}
      />
      <Flex
        flexDir="column"
        ml="10px"
        justify="space-between"
        mt={["10px", "0px"]}
      >
        <Text noOfLines={4} fontSize={["12px", "14px"]}>
          {item?.news_name}
        </Text>
        <Text
          mt="10px"
          fontSize={["12px", "14px"]}
          textAlign="right"
          fontWeight="bold"
          color="#dc3a56"
        >
          LEER MÁS
        </Text>
      </Flex>
    </Flex>
  );
}
export default ItemList;
