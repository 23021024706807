import React, { useState, useEffect } from "react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Stack,
} from "@chakra-ui/react";
import axios from "axios";
import { useSelector } from "react-redux";

import { Textarea } from "../index";
import { BASE_URL } from "../../utils/config";

function ModalPedirMusica({ isOpen, onClose = () => {} }) {
  const { user } = useSelector((store) => store.user);

  const [text, setText] = useState("");
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setText("");
    setLoading(false);
  }, [isOpen]);

  const handleRequest = () => {
    setLoading(true);
    const data = new FormData();
    data.append("pedir_musica", true);
    data.append("request_music_form", text);
    data.append("users_app_id", user?.cid);
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    };
    axios
      .post(`${BASE_URL}/ApiRadio.php`, data, config)
      .then((resp) => {
        const data = resp?.data;
        if (data?.error) {
          alert(data?.message);
        } else {
          alert("Solicitud realizada con éxito");
          onClose();
        }
      })
      .catch(() => {
        alert(
          "Hubo un error al procesar su solicitud, inténtelo de nuevo más tarde."
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Solicitar musica</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack>
            <Textarea
              label="Artista y musica"
              value={text}
              onChange={(e) => setText(e?.target?.value)}
            />
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" size="sm">
            Cancelar
          </Button>
          <Button
            colorScheme="blue"
            ml={3}
            size="sm"
            onClick={handleRequest}
            isLoading={isLoading}
            isDisabled={text === ""}
          >
            Enviar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ModalPedirMusica;
