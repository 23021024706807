import React from "react";

import { Flex, Text, SimpleGrid } from "@chakra-ui/react";
import { useSelector } from "react-redux";

import { ItemList } from "./components";

function Podcasts() {
  const { podcasts } = useSelector((store) => store.config);

  return (
    <Flex
      flex="1"
      flexDir="column"
      px={["5%", "5%", "20%"]}
      pb={["100%", "10%"]}
      mt={["20px", "0px"]}
      overflowY="auto"
    >
      <Flex
        mt={["5px", "20px"]}
        justify="space-between"
        alignItems="center"
        bg="#dc3a56"
        w="100%"
        py="20px"
        px="20px"
        borderTopRadius="5px"
      >
        <Text color="white" fontWeight="600">
          Podcasts
        </Text>
      </Flex>

      <SimpleGrid
        mt="20px"
        columns={3}
        spacing={["0px", "20px"]}
        flex="1"
        minChildWidth="230px"
      >
        {podcasts.map((item) => (
          <ItemList item={item} key={`podcasts-${item?.cid}`} />
        ))}
      </SimpleGrid>
    </Flex>
  );
}

export default Podcasts;
