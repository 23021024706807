import React, { useState, useEffect } from "react";
import { Flex, Image, IconButton, Icon } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { motion } from "framer-motion";

import { BASE_URL_SLIDER_IMAGE } from "../../../utils/config";

let time;
function Sliders() {
  const { sliders = [] } = useSelector((store) => store.config);

  const [position, setPosition] = useState(0);

  const slider = sliders[position];

  const nextPage = () => {
    if (position < sliders.length - 1) {
      setPosition((p) => p + 1);
    }
  };
  const backPage = () => {
    if (position > 0) {
      setPosition((p) => p - 1);
    }
  };

  useEffect(() => {
    clearInterval(time);
    time = setInterval(() => {
      if (position === sliders.length - 1) {
        setPosition(0);
      } else {
        nextPage();
      }
    }, 5000);
  }, [position]);

  if (sliders.length === 0) return <></>;

  return (
    <Flex pos="relative" h={["100%", "100%"]}>
      <motion.div
        animate={{
          scale: position > 0 ? 1 : 0,
        }}
      >
        <IconButton
          colorScheme="red"
          borderRadius="100%"
          aria-label="voltar"
          icon={<Icon as={AiOutlineLeft} />}
          pos="absolute"
          left="-5"
          top="45%"
          onClick={backPage}
          zIndex={10}
        />
      </motion.div>
      <Image
        as={motion.img}
        w="100%"
        key={`slider-${slider?.id}`}
        src={`${BASE_URL_SLIDER_IMAGE}${slider?.slider_image}`}
        alt={slider?.slider_title}
        initial={{ opacity: 0, duration: 5 }}
        animate={{ opacity: 1, duration: 5 }}
        exit={{ opacity: 0, duration: 5 }}
      />

      <motion.div
        animate={{
          scale: position < sliders.length - 1 ? 1 : 0,
        }}
      >
        <IconButton
          colorScheme="red"
          borderRadius="100%"
          aria-label="avançar"
          icon={<Icon as={AiOutlineRight} />}
          pos="absolute"
          right="-5"
          top="45%"
          onClick={nextPage}
          zIndex={10}
        />
      </motion.div>
    </Flex>
  );
}

export default Sliders;
