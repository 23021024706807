import React, {
  useEffect,
  createContext,
  useContext,
  useState,
  createRef,
} from "react";

const AudioContextProvider = createContext({});

export function AudioContext({ children }) {
  const audioRef = createRef();

  const [audioLink, setAudioLink] = useState("");

  useEffect(() => {
    if (audioLink) {
      audioRef?.current?.play();
    } else {
      audioRef?.current?.pause();
      audioRef?.current?.load();
    }
  }, [audioLink, audioRef]);

  const isPlaying = (link) => audioLink === link;
  const play = (link) => setAudioLink(link ? link : "");

  return (
    <AudioContextProvider.Provider value={{ isPlaying, play }}>
      <audio src={audioLink} ref={audioRef} />
      {children}
    </AudioContextProvider.Provider>
  );
}

export const useAudio = () => useContext(AudioContextProvider);
