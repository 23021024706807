import React, { useState, useEffect } from "react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Stack,
} from "@chakra-ui/react";
import axios from "axios";
import { useDispatch } from "react-redux";

import { Input, Select } from "../index";
import { BASE_URL } from "../../utils/config";
import { setUser } from "../../store/modules/user/actions";

function ModalAuth({ isOpen, onClose = () => {}, onCloseAll = () => {} }) {
  const dispatch = useDispatch();

  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [sexo, setSexo] = useState("Masculino");
  const [telefone, setTelefone] = useState("");
  const [dataNascimento, setDataNascimento] = useState("");
  const [cidade, setCidade] = useState("");
  const [pais, setPais] = useState("");
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setNome("");
    setEmail("");
    setSenha("");
    setSexo("Masculino");
    setTelefone("");
    setDataNascimento("");
    setCidade("");
    setPais("");
    setLoading(false);
  }, []);

  const validationForm = () => {
    if (nome.trim() === "") return true;
    if (email.trim() === "") return true;
    if (senha.trim() === "") return true;
    if (telefone.trim() === "") return true;
    if (dataNascimento.trim() === "") return true;
    if (cidade.trim() === "") return true;
    if (pais.trim() === "") return true;
    return false;
  };

  const handleRegister = () => {
    setLoading(true);
    const data = new FormData();
    data.append("cadastro", true);
    data.append("users_name", nome);
    data.append("users_email", email);
    data.append("users_password", senha);
    data.append("users_sex", sexo);
    data.append("users_phone", telefone);
    data.append("users_birthday", dataNascimento);
    data.append("users_country", pais);
    data.append("users_city", cidade);

    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    };
    axios
      .post(`${BASE_URL}/ApiRadio.php`, data, config)
      .then((resp) => {
        const data = resp?.data;
        if (data?.error) {
          alert(data?.message);
        } else {
          dispatch(setUser(data));
          onCloseAll();
          alert("El registro se realizó con éxito.");
        }
      })
      .catch(() => {
        alert(
          "Hubo un error al procesar su solicitud, inténtelo de nuevo más tarde."
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Registro</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack>
            <Input
              label="Nombre completo"
              value={nome}
              onChange={(e) => setNome(e?.target?.value)}
            />
            <Input
              label="Correo electrónico"
              value={email}
              onChange={(e) => setEmail(e?.target?.value)}
            />
            <Input
              label="Contraseña"
              value={senha}
              onChange={(e) => setSenha(e?.target?.value)}
            />
            <Select
              label="Sexo"
              value={sexo}
              onChange={(e) => setSexo(e?.target?.value)}
              listPropriedade="id"
              list={[{ id: "Masculino" }, { id: "Feminino" }]}
            />
            <Input
              label="Teléfono"
              value={telefone}
              onChange={(e) => setTelefone(e?.target?.value)}
            />
            <Input
              label="Fecha de nacimiento"
              type="date"
              value={dataNascimento}
              onChange={(e) => setDataNascimento(e?.target?.value)}
            />
            <Input
              label="Ciudad"
              value={cidade}
              onChange={(e) => setCidade(e?.target?.value)}
            />
            <Input
              label="País"
              value={pais}
              onChange={(e) => setPais(e?.target?.value)}
            />
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" size="sm">
            Cancelar
          </Button>
          <Button
            colorScheme="blue"
            ml={3}
            size="sm"
            onClick={handleRegister}
            isDisabled={validationForm()}
            isLoading={isLoading}
          >
            Registrar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ModalAuth;
