import React, { useState, useEffect } from "react";

import { Flex, useBreakpointValue, Text, Icon, Button } from "@chakra-ui/react";

import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import { BsPlayFill, BsPauseFill } from "react-icons/bs";
import { FiMusic } from "react-icons/fi";
import { FaGooglePlay, FaApple, FaSignInAlt } from "react-icons/fa";
import { ModalAuth, ModalPedirMusica, ModalPerfil } from "../index";
import { logout } from "../../store/modules/user/actions";
import { useAudio } from "../../contexts/Audio";

let time;
function Player({ children }) {
  const dispatch = useDispatch();
  const { play, isPlaying } = useAudio();

  const [isOpenModalAuth, setOpenModalAuth] = useState(false);
  const [isOpenModalPedirMusica, setOpenModalPedirMusica] = useState(false);
  const [isOpenModalPerfil, setOpenModalPerfil] = useState(false);

  const [tocandoAgora, setTocandoAgora] = useState();

  const { radio, config } = useSelector((store) => store.config);
  const { user } = useSelector((store) => store.user);

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  useEffect(() => {
    carregarInformacaoPlayer();
  }, []);

  useEffect(() => {
    if (radio?.radio_url && radio?.metadata_type == "true") {
      carregarInformacaoPlayer();
      clearInterval(time);
      time = setInterval(() => {
        carregarInformacaoPlayer();
      }, 30000);
    } else {
      setTocandoAgora();
    }
  }, [radio]);

  function carregarInformacaoPlayer() {
    function transformarURL(url) {
      const regex = /^(https?:\/\/[^/]+)/;
      const matches = url.match(regex);
      if (matches && matches.length > 0) {
        const baseURL = matches[0];
        const novaURL = baseURL + "/index.html?sid=1";
        return novaURL;
      } else {
        return url;
      }
    }
    axios.get(transformarURL(radio?.radio_url)).then((resp) => {
      try {
        const data = resp.data;
        const regex = /<a href="currentsong\?sid=1">(.*?)<\/a>/;
        const match = data.match(regex);
        if (match) {
          setTocandoAgora(match[1]);
        } else {
          setTocandoAgora("");
        }
      } catch (error) {}
    });
  }

  const radioIsPlaying = isPlaying(radio?.radio_url);

  const playRadio = () => {
    if (radioIsPlaying) {
      play(false);
    } else {
      play(radio?.radio_url);
    }
  };

  return (
    <Flex flexDir="column" flex="1" overflowY="none">
      {isWideVersion && (
        <Flex
          px={["5%", "5%", "20%"]}
          py="10px"
          align="center"
          justify="space-between"
          pos="fixed"
          top="0"
          bg="white"
          w="100%"
          zIndex="999"
        >
          <Flex flex="1" align="center">
            <Icon
              as={radioIsPlaying ? BsPauseFill : BsPlayFill}
              color="blue.400"
              fontSize="30px"
              cursor="pointer"
              onClick={playRadio}
            />

            <Text fontSize="14px" ml="10px" maxW="180px" noOfLines={1}>
              {tocandoAgora}
            </Text>

            <Flex
              bg="blue.400"
              ml="40px"
              color="white"
              px="10px"
              py="5px"
              borderRadius="0px"
              align="center"
            >
              {/*<Icon
                as={FaApple}
                mr="5px"
                color="white"
                fontSize="18px"
                cursor="pointer"
                onClick={() => {
                  window.open(config?.app_ios_link);
                }}
              />*/}
              <Icon
                as={FaGooglePlay}
                mr="5px"
                color="white"
                fontSize="14px"
                cursor="pointer"
                onClick={() => {
                  window.open(config?.app_android_link);
                }}
              />
              <Text fontSize="12px" fontWeight="500">
                <b>DESCARGA NUESTRA APP</b>
              </Text>
            </Flex>
            {/*} <Flex
              bg="blue.400"
              ml="10px"
              color="white"
              px="10px"
              py="5px"
              borderRadius="0px"
              align="center"
              cursor="pointer"
              onClick={() => {
                if (user) {
                  setOpenModalPedirMusica(true);
                } else {
                  setOpenModalAuth(true);
                }
              }}
            >
              <Icon
                as={FiMusic}
                mr="5px"
                color="white"
                fontSize="14px"
                cursor="pointer"
              />
              <Text fontSize="12px" fontWeight="500"><b>
                PIDE TU MUSICA
              </b>
              </Text>
            </Flex>*/}
          </Flex>
          {/*{user ? (
            <Flex align="center" ml="15px">
              <Text fontWeight="bold" color="blackAlpha.600" fontSize="14px">
                Hola, {user?.users_name}
              </Text>
              <Button
                variant="link"
                size="sm"
                colorScheme="blue"
                ml="15px"
                onClick={() => setOpenModalPerfil(true)}
              >
                Perfil
              </Button>
              <Button
                variant="link"
                size="sm"
                colorScheme="blue"
                ml="20px"
                onClick={() => dispatch(logout())}
              >
                Salir
              </Button>
            </Flex>
          ) : (
            <Button
              size="sm"
              colorScheme="blue"
              leftIcon={<Icon as={FaSignInAlt} />}
              onClick={() => {
                if (user) {
                  dispatch(logout());
                } else {
                  setOpenModalAuth(true);
                }
              }}
            >
              {user ? "Logout" : "Entrar"}
            </Button>
            )} */}
        </Flex>
      )}
      <Flex flex="1" overflowY="auto" mt={isWideVersion ? "0px" : "70px"}>
        {children}
      </Flex>

      {!isWideVersion && (
        <Flex
          h="55px"
          w="100%"
          px={["5%"]}
          py="10px"
          align="center"
          pos="fixed"
          bottom="0"
          bg="white"
          boxShadow="md"
        >
          <Icon
            as={radioIsPlaying ? BsPauseFill : BsPlayFill}
            color="blue.400"
            fontSize="30px"
            cursor="pointer"
            onClick={playRadio}
          />

          <Text fontSize="14px" ml="10px" maxW="150px" noOfLines={1}>
            {tocandoAgora}
          </Text>
        </Flex>
      )}

      <ModalAuth
        isOpen={isOpenModalAuth}
        onClose={() => setOpenModalAuth(false)}
      />
      <ModalPerfil
        isOpen={isOpenModalPerfil}
        onClose={() => setOpenModalPerfil(false)}
      />
      <ModalPedirMusica
        isOpen={isOpenModalPedirMusica}
        onClose={() => setOpenModalPedirMusica(false)}
      />
    </Flex>
  );
}

export default Player;
