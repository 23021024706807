import React from "react";

import { Flex, Text, SimpleGrid } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { ItemList } from "./components";
import { setPromocao } from "../../store/modules/config/actions";

function Promocoes() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { promocoes } = useSelector((store) => store.config);

  const handleClickItem = (item) => {
    dispatch(setPromocao(item));
    history.push("promocoes/detalhes");
  };

  return (
    <Flex
      flex="1"
      flexDir="column"
      px={["5%", "5%", "20%"]}
      pb={["100%", "10%"]}
      mt={["20px", "0px"]}
      overflowY="auto"
    >
      <Flex
        mt={["5px", "20px"]}
        justify="space-between"
        alignItems="center"
        bg="blue.400"
        w="100%"
        py="10px"
        px="20px"
        borderTopRadius="5px"
      >
        <Text color="white" fontWeight="600">
          Promociones
        </Text>
      </Flex>

      <SimpleGrid mt="20px" columns={[1, 2]} spacing="20px" flex="1">
        {promocoes.map((item) => (
          <ItemList
            item={item}
            key={`promotions-${item?.id}`}
            onClick={() => {
              handleClickItem(item);
            }}
          />
        ))}
      </SimpleGrid>
    </Flex>
  );
}

export default Promocoes;
