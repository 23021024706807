import React, { useState, useMemo } from "react";

import {
  Flex,
  Image,
  HStack,
  Text,
  Stack,
  useBreakpointValue,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Icon,
  IconButton,
} from "@chakra-ui/react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { RiMenuLine } from "react-icons/ri";
import { useSelector, useDispatch } from "react-redux";
import { isAfter, isBefore, parse } from "date-fns";

import { ModalAuth } from "../index";
import { logout } from "../../store/modules/user/actions";
import {
  FaTiktok,
  FaYoutube,
  FaFacebook,
  FaInstagram,
  FaTwitter,
} from "react-icons/fa";

import { BASE_URL_LOGO, BASE_IMAGE_PROGRAMACAO } from "../../utils/config";
import { getDiaProgramacao, getDiaProgramacaoProximo } from "../../utils";

function Header() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();

  const [isOpenModalAuth, setOpenModalAuth] = useState(false);
  const { user } = useSelector((store) => store.user);
  const { config } = useSelector((store) => store.config);

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  if (isWideVersion) {
    return (
      <Flex px="20%" flexDir="column" mt="70px">
        <Flex justify="space-between" gap="10">
          <Image
            src={`${BASE_URL_LOGO}${config?.site_logo}`}
            h="200px"
            alignSelf="flex-start"
          />
          <ItemLiveProgramming />
        </Flex>

        <Flex
          bg="white"
          mt="15px"
          py="15px"
          px="20px"
          borderRadius="5px"
          overflowX="auto"
        >
          <HStack spacing="45px" flex="1">
            <ItemList title="INICIO" router="/home" />
            {/*<ItemList title="PROMOCIONES" router="/promocoes" />*/}
            <ItemList title="PODCAST" router="/podcasts" />
            <ItemList title="NOTICIAS" router="/noticias" />
            <ItemList title="VÍDEOS" router="/videos" />
            <ItemList title="PROGRAMAS" router="/programas" />
            <ItemList title="CONTACTO" router="/contato" />
          </HStack>
          <HStack ml="15px">
            {config?.tiktok_type === "true" && (
              <ItemSocial url={config?.site_tiktok} CIcon={FaTiktok} />
            )}
            {config?.youtube_type === "true" && (
              <ItemSocial url={config?.site_youtube} CIcon={FaYoutube} />
            )}
            {config?.facebook_type === "true" && (
              <ItemSocial url={config?.site_facebook} CIcon={FaFacebook} />
            )}
            {config?.instagram_type === "true" && (
              <ItemSocial url={config?.site_instagram} CIcon={FaInstagram} />
            )}
            {config?.twitter_type === "true" && (
              <ItemSocial url={config?.site_twitter} CIcon={FaTwitter} />
            )}
          </HStack>
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex
      h="70px"
      w="100%"
      bg="white"
      pt="15px"
      pb="20px"
      align="center"
      boxShadow="md"
      pos="fixed"
      top="0"
      zIndex="999"
    >
      <Flex flex="1" mt="10px" ml="10px">
        <IconButton
          icon={<Icon as={RiMenuLine} />}
          fontSize="25px"
          variant="unstyled"
          onClick={onOpen}
          color="black"
        />
      </Flex>

      <Link to="/">
        <Image src={`${BASE_URL_LOGO}${config?.site_logo}`} h="40px" />
      </Link>
      <Flex flex="1" justify="flex-end" mt="10px" visibility="hidden">
        <IconButton
          fontSize="25px"
          variant="unstyled"
          mr="2"
          colorScheme="transaparent"
        />
      </Flex>

      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent bg="white" p="2" color="black">
            <DrawerCloseButton mt="4" />
            <DrawerHeader>
              <Image src={`${BASE_URL_LOGO}${config?.site_logo}`} />
            </DrawerHeader>
            <DrawerBody>
              <Stack mt="20px" spacing="20px">
                <ItemMenuMobile
                  title="Inicio"
                  router="/home"
                  onClick={onClose}
                />
                {/*<ItemMenuMobile
                  title="Promociones"
                  router="/promocoes"
                  onClick={onClose}
                />*/}
                <ItemMenuMobile
                  title="Podcast"
                  router="/podcasts"
                  onClick={onClose}
                />
                <ItemMenuMobile
                  title="Noticias"
                  router="/noticias"
                  onClick={onClose}
                />
                <ItemMenuMobile
                  title="Vídeos"
                  router="/videos"
                  onClick={onClose}
                />
                <ItemMenuMobile
                  title="Programas"
                  router="/programas"
                  onClick={onClose}
                />
                <ItemMenuMobile
                  title="Contacto"
                  router="/contato"
                  onClick={onClose}
                />
                <ItemMenuMobile
                  title={user ? "Salir" : "Entrar"}
                  onClick={() => {
                    if (!user) {
                      setOpenModalAuth(true);
                    } else {
                      dispatch(logout());
                    }
                    onClose();
                  }}
                />
              </Stack>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
      <ModalAuth
        isOpen={isOpenModalAuth}
        onClose={() => setOpenModalAuth(false)}
      />
    </Flex>
  );
}

const ItemLiveProgramming = () => {
  const { programas } = useSelector((store) => store.config);

  const [programacaoAtual, proximaProgramacao] = useMemo(() => {
    const proximoDiaProgramacao = [];
    const programacaoList = [];
    let programacaoItem;
    let proximaProgramacao;
    (programas || []).forEach((programacao) => {
      if (getDiaProgramacaoProximo(programacao)) {
        proximoDiaProgramacao.push(programacao);
      }
      if (getDiaProgramacao(programacao)) {
        const dataStart = parse(
          programacao.program_hour_start,
          "HH:mm",
          new Date()
        );
        const dataEnd = parse(
          programacao.program_hour_end,
          "HH:mm",
          new Date()
        );
        programacaoList.push(programacao);
        if (isAfter(new Date(), dataStart) && isBefore(new Date(), dataEnd)) {
          programacaoItem = programacao;
        }
      }
    });
    programacaoList.sort((a, b) => {
      const date1 = parse(a.program_hour_start, "HH:mm", new Date());
      const date2 = parse(b.program_hour_start, "HH:mm", new Date());
      return date1.getTime() - date2.getTime();
    });

    proximoDiaProgramacao.sort((a, b) => {
      const date1 = parse(a.program_hour_start, "HH:mm", new Date());
      const date2 = parse(b.program_hour_start, "HH:mm", new Date());
      return date1.getTime() - date2.getTime();
    });

    programacaoList.forEach((item, index) => {
      if (
        programacaoItem &&
        item.program_name === programacaoItem.program_name
      ) {
        if (
          item.program_hour_end == "24:00" ||
          item.program_hour_end == "23:59" ||
          item.program_hour_end == "00:00"
        ) {
          if (proximoDiaProgramacao.length > 0) {
            proximaProgramacao = proximoDiaProgramacao[0];
          }
        } else {
          if (programacaoList.length > index + 1) {
            proximaProgramacao = programacaoList[index + 1];
            return;
          }
        }
      }
    });

    return [programacaoItem, proximaProgramacao];
  }, [programas]);

  return (
    <Flex
      gap="5"
      bg="blackAlpha.700"
      p="3"
      pr="12"
      h="min"
      mt="40px"
      borderRadius="md"
      color="white"
    >
      <Image
        h="90px"
        w="90px"
        src={`${BASE_IMAGE_PROGRAMACAO}${programacaoAtual?.announcer_image}`}
      />
      <Flex flexDir="column" gap="1">
        <Flex gap="2">
          <Flex
            bg="red"
            px="1"
            align="center"
            justify="center"
            borderRadius="md"
          >
            <Text fontSize="xs" fontWeight="bold" noOfLines={1}>
              EN VIVO
            </Text>
          </Flex>
          <Text fontWeight="bold" noOfLines={1}>
            {programacaoAtual?.announcer_name}
          </Text>
        </Flex>
        {programacaoAtual?.program_name && (
          <Text noOfLines={1} fontWeight="bold">
            Agora: {programacaoAtual.program_name}
          </Text>
        )}
        {proximaProgramacao?.program_name && (
          <Text noOfLines={1} fontWeight="bold">
            A seguir: {proximaProgramacao?.program_name}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};
export const ItemSocial = ({ CIcon, url, color = "#a22e42" }) => (
  <Flex
    cursor="pointer"
    onClick={() => window.open(url)}
    p="3px"
    borderWidth="2px"
    borderColor={color}
    borderRadius="3px"
  >
    <Icon as={CIcon} color={color} />
  </Flex>
);

const ItemMenuMobile = ({ title, router, onClick = () => {} }) => {
  const history = useHistory();

  return (
    <Flex
      onClick={() => {
        onClick();
        setTimeout(() => {
          history.push(router);
        }, 500);
      }}
    >
      <Text
        cursor="pointer"
        color="black.650"
        _hover={{
          color: "black",
          textDecoration: "underline",
        }}
      >
        {title}
      </Text>
    </Flex>
  );
};

const ItemList = ({ title, router }) => {
  const location = useLocation();

  return (
    <Link to={router}>
      <Flex
        cursor="pointer"
        transition="ease-in-out 0.3s"
        color={location.pathname.includes(router) ? "#a22e42" : "black"}
        _hover={{
          color: "#a22e42",
        }}
      >
        <Text fontSize="14px" fontWeight="700">
          {title}
        </Text>
      </Flex>
    </Link>
  );
};

export default Header;
