import produce from "immer";

const INITIALSTATE = {
  radio: null,
  sliders: [],
  promocoes: [],
  promocao: null,
  top_musicas: [],
  podcasts: [],
  videos: [],
  video: null,
  noticias: [],
  noticia: null,
  programas: [],
  promocoes_ganhadores: [],
  config: null,
};

export default function config(state = INITIALSTATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@config/SET_CONFIG": {
        draft.config = action.payload.config;
        break;
      }
      case "@config/SET_RADIO": {
        draft.radio = action.payload.radio;
        break;
      }
      case "@config/SET_SLIDERS": {
        draft.sliders = action.payload.sliders;
        break;
      }
      case "@config/SET_PROMOCOES": {
        draft.promocoes = action.payload.promocoes;
        break;
      }
      case "@config/SET_PROMOCAO": {
        draft.promocao = action.payload.promocao;
        break;
      }
      case "@config/SET_TOP_MUSICAS": {
        draft.top_musicas = action.payload.top_musicas;
        break;
      }
      case "@config/SET_PODCASTS": {
        draft.podcasts = action.payload.podcasts;
        break;
      }
      case "@config/SET_VIDEOS": {
        draft.videos = action.payload.videos;
        break;
      }
      case "@config/SET_VIDEO": {
        draft.video = action.payload.video;
        break;
      }
      case "@config/SET_NOTICIAS": {
        draft.noticias = action.payload.noticias;
        break;
      }
      case "@config/SET_NOTICIA": {
        draft.noticia = action.payload.noticia;
        break;
      }
      case "@config/SET_PROGRAMAS": {
        draft.programas = action.payload.programas;
        break;
      }
      case "@config/SET_PROMOCOES_GANHADORES": {
        draft.promocoes_ganhadores = action.payload.list;
        break;
      }
      default:
        return state;
    }
  });
}
