import React from "react";

import { Flex, Text, Input as ChakraInput } from "@chakra-ui/react";

function Input({ label, styleContainer, styleLabel, ...rest }) {
  return (
    <Flex flexDir="column" {...styleContainer}>
      {label && (
        <Text
          textAlign="left"
          mr="15px"
          fontWeight="500"
          color="blackAlpha.700"
          {...styleLabel}
        >
          {label}
        </Text>
      )}
      <ChakraInput {...rest} />
    </Flex>
  );
}

export default Input;
