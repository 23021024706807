import React, { useState, useEffect } from "react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Stack,
} from "@chakra-ui/react";
import axios from "axios";

import { Input } from "../index";
import { BASE_URL } from "../../utils/config";

function RecoveryPassword({ isOpen, onClose = () => {} }) {
  const [email, setEmail] = useState("");
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setEmail("");
    setLoading(false);
  }, []);

  const validationForm = () => {
    if (email.trim() === "") return true;
    return false;
  };

  const handleRequest = () => {
    setLoading(true);
    const data = new FormData();
    data.append("recuperar_senha", true);
    data.append("users_email", email);

    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    };
    axios
      .post(`${BASE_URL}/ApiRadio.php`, data, config)
      .then((resp) => {
        const data = resp?.data;
        if (data?.error) {
          alert(data?.message);
        } else {
          alert("Su contraseña ha sido enviada a su correo electrónico.");
          alert(
            "Si no lo encuentra en su bandeja de entrada, revise también su carpeta de correo no deseado. (Spam)"
          );
        }
        onClose();
      })
      .catch(() => {
        alert(
          "Hubo un error al procesar su solicitud, inténtelo de nuevo más tarde."
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Recuperar contraseña</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack>
            <Input
              label="Correo electrónico"
              value={email}
              onChange={(e) => setEmail(e?.target?.value)}
            />
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" size="sm">
            Cancelar
          </Button>
          <Button
            colorScheme="blue"
            ml={3}
            size="sm"
            onClick={handleRequest}
            isDisabled={validationForm()}
            isLoading={isLoading}
          >
            Enviar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default RecoveryPassword;
