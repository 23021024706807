import React from "react";

import { Flex, Text, Image, Icon } from "@chakra-ui/react";

import { BsPlayCircleFill } from "react-icons/bs";

function ItemList({
  item,
  onClick = () => {},
  objectFit = "contain",
  ...rest
}) {
  return (
    <Flex
      w="250px"
      bg="white"
      borderRadius="5px"
      boxShadow="md"
      _hover={{
        boxShadow: "lg",
        "#gradient": {
          visibility: "visible",
        },
      }}
      h="140px"
      cursor="pointer"
      flexDir={["column", "row"]}
      pos="relative"
      onClick={onClick}
      {...rest}
    >
      <Image
        src={`https://img.youtube.com/vi/${item?.video_id}/maxresdefault.jpg`}
        w="100%"
        h="100%"
        borderRadius="5px"
        alt={item?.video_song_name}
        objectFit={objectFit}
      />
      <Flex
        visibility="hidden"
        id="gradient"
        pos="absolute"
        top="0"
        bottom="0"
        left="0"
        right="0"
        bg="rgba(0,0,0,0.6)"
        borderRadius="5px"
        flexDir="row"
        align="flex-end"
        p="10px"
      >
        <Flex flexDir="column" flex="1">
          <Text color="white" fontWeight="600" fontSize="14px">
            {item?.video_song_name}
          </Text>
          <Text color="white" fontSize="10px">
            {item?.video_artist}
          </Text>
        </Flex>
        <Flex ml="10px">
          <Icon as={BsPlayCircleFill} color="white" fontSize="30px" />
        </Flex>
      </Flex>
    </Flex>
  );
}
export default ItemList;
