import React, { useState } from "react";

import { Flex, Text, Stack, Button } from "@chakra-ui/react";
import axios from "axios";

import { Input, Textarea } from "../../components";
import { BASE_URL } from "../../utils/config";

function Contato() {
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [mensagem, setMensagem] = useState("");

  const [isLoading, setLoading] = useState(false);

  const handleRequest = () => {
    setLoading(true);
    const data = new FormData();
    data.append("contato", true);
    data.append("contact_full_name", nome);
    data.append("contact_email", email);
    data.append("contact_message", mensagem);

    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    };
    axios
      .post(`${BASE_URL}/ApiRadio.php`, data, config)
      .then((resp) => {
        const data = resp?.data;
        if (data?.error) {
          alert(data?.message);
        } else {
          alert("Mensagem enviada com sucesso");
          setEmail("");
          setNome("");
          setMensagem("");
        }
      })
      .catch(() => {
        alert(
          "Ocorreu um error ao processar sua solicitação, tente novamente mais tarde."
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Flex
      flex="1"
      flexDir="column"
      px={["5%", "5%", "20%"]}
      pb={["100%", "10%"]}
      mt={["20px", "0px"]}
      overflowY="auto"
    >
      <Flex
        mt={["5px", "20px"]}
        justify="space-between"
        alignItems="center"
        bg="#dc3a56"
        w="100%"
        py="20px"
        px="20px"
        borderTopRadius="5px"
      >
        <Text color="white" fontWeight="600">
          Contacto
        </Text>
      </Flex>

      <Flex bg="white" p="20px" flexDir="column">
        <Stack spacing="15px">
          <Input
            label="Nombre completo"
            value={nome}
            onChange={(e) => setNome(e?.target?.value)}
          />
          <Input
            label="E-mail"
            value={email}
            onChange={(e) => setEmail(e?.target?.value)}
          />
          <Textarea
            label="Mensaje"
            value={mensagem}
            onChange={(e) => setMensagem(e?.target?.value)}
          />

          <Button
            alignSelf="flex-end"
            colorScheme="blue"
            isLoading={isLoading}
            onClick={handleRequest}
            isDisabled={
              nome.trim() === "" ||
              mensagem.trim() === "" ||
              email.trim() === ""
            }
          >
            Enviar
          </Button>
        </Stack>
      </Flex>
    </Flex>
  );
}

export default Contato;
