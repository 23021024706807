import React from "react";

import { Flex, Image } from "@chakra-ui/react";

import { BASE_URL_PROMOCAO_IMAGE } from "../../../utils/config";

function ItemList({ item, onClick = () => {} }) {
  return (
    <Flex
      onClick={onClick}
      borderWidth="2px"
      _hover={{
        borderWidth: "2px",
        borderColor: "red",
      }}
      w="100%"
      h="200px"
      borderRadius="5px"
      cursor="pointer"
    >
      <Image
        src={`${BASE_URL_PROMOCAO_IMAGE}${item?.promotions_image}`}
        w="100%"
        h="196px"
        borderRadius="5px"
      />
    </Flex>
  );
}

export default ItemList;
