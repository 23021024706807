import React, { useEffect, useState } from "react";

import { Flex, Text, Box } from "@chakra-ui/react";
import { motion } from "framer-motion";

import { useSelector } from "react-redux";

function Ganhadores(props) {
  const { promocoes_ganhadores = [] } = useSelector((store) => store.config);

  const [index, setIndex] = useState(0);
  const ganhador = promocoes_ganhadores.length && promocoes_ganhadores[index];

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (index >= promocoes_ganhadores.length - 1) {
        setIndex(0);
      } else {
        setIndex((count) => count + 1);
      }
    }, 2000);
    return () => {
      clearInterval(intervalId);
    };
  }, [index]);

  return (
    <Flex
      flex="1"
      bg="white"
      borderRadius="5px"
      py="20px"
      flexDir="column"
      boxShadow="md"
      {...props}
    >
      <Text textAlign="center" fontWeight="700" w="100%">
        ÚLTIMOS GANADORES
      </Text>
      {promocoes_ganhadores.length === 0 ? (
        <Flex flex="1" justify="center" align="center" py="50px">
          <Text>Sin Ganadores</Text>
        </Flex>
      ) : (
        <Flex
          flex="1"
          justify="center"
          align="center"
          pt="30px"
          px="20px"
          flexDir="column"
          key={index}
          as={motion.div}
          animate={{ scale: [0, 1, 0.5, 1] }}
          transition={{ times: [0, 0.1, 0.9, 1] }}
        >
          <Flex flexDir="column" flex="1" justify="center" align="center">
            <Text fontWeight="300" fontSize="20px" textAlign="center">
              {ganhador?.users_name}
            </Text>
            <Text
              color="blue.500"
              fontWeight="700"
              textTransform="uppercase"
              textAlign="center"
            >
              {ganhador?.promotions_name}
            </Text>
          </Flex>
          <Flex mt="20px">
            {promocoes_ganhadores.map((item) => (
              <Box
                mx="5px"
                h="15px"
                w="15px"
                borderRadius="15px"
                bg={item === ganhador ? "blue.400" : "gray.200"}
              />
            ))}
          </Flex>
        </Flex>
      )}
    </Flex>
  );
}

export default Ganhadores;
