import React from "react";
import { Flex, Text, Image, Icon, Center, SimpleGrid } from "@chakra-ui/react";

import { BsFillPlayFill, BsFillPauseFill } from "react-icons/bs";
import { AiFillTrophy } from "react-icons/ai";

import { useSelector } from "react-redux";

import {
  BASE_URL_TOP_MUSICA_IMAGE,
  BASE_URL_TOP_MUSICA_AUDIO,
} from "../../../utils/config";
import { useAudio } from "../../../contexts/Audio";

function TopMusicas(props) {
  const { top_musicas = [] } = useSelector((store) => store.config);

  const playAudio = (item) => {
    window.location.href = `${BASE_URL_TOP_MUSICA_AUDIO}${item?.topmusic_file}`;
  };

  return (
    <Flex flexDir="column" w="100%" {...props}>
      <Flex
        justify="space-between"
        alignItems="center"
        bg="#dc3a56"
        w="100%"
        py="10px"
        px="20px"
        borderTopRadius="5px"
      >
        <Flex align="center">
          <Icon as={AiFillTrophy} color="white" fontSize="35px" />
          <Text color="white" fontWeight="600" ml="10px"><b>
            TOP MUSICA
          </b>
          </Text>
        </Flex>
      </Flex>
      <Flex mt="10px" flexDir="column">
        <SimpleGrid columns={[2, 2, 3, 3, 5, 5]} spacing={10}>
          {top_musicas.slice(0, 10).map((item, index) => (
            <ItemList
              key={`top-music-${item?.cid}`}
              item={item}
              index={index}
              onClickPlay={() => playAudio(item)}
            />
          ))}
        </SimpleGrid>
      </Flex>
    </Flex>
  );
}

const ItemList = ({ item, index, onClickPlay = () => {} }) => {
  const { play, isPlaying } = useAudio();

  const fileUrl = `${BASE_URL_TOP_MUSICA_AUDIO}${item?.topmusic_file}`;

  const isPlayingAudio = isPlaying(fileUrl);

  const playAudio = () => {
    if (isPlayingAudio) {
      play();
    } else {
      play(fileUrl);
    }
  };

  return (
    <Flex
      w="100%"
      h="100%"
      m="0px"
      justify="flex-end"
      alignItems="flex-end"
      pos="relative"
      cursor="pointer"
    >
      <Flex
        w="40px"
        h="40px"
        p="10px"
        pos="absolute"
        left="0"
        bottom="0"
        align="center"
        justify="center"
        borderRadius="0px"
        bg="#dc3a56"
      >
        <Text fontSize="22px" color="white" fontWeight="600">
          {index + 1}
        </Text>
      </Flex>
      <Image
        borderRadius="0px"
        w="100%"
        h="100%"
        src={`${BASE_URL_TOP_MUSICA_IMAGE}${item?.topmusic_image}`}
      />
      <Flex
        pos="absolute"
        zIndex="2"
        top="0"
        bottom="0"
        left="0"
        right="0"
        borderRadius="10px"
        _hover={{
          bg: "rgba(0,0,0,0.7)",
          "#info": {
            visibility: "visible",
          },
        }}
      >
        <Flex
          align="center"
          justify="center"
          flex="1"
          flexDir="column"
          id="info"
          visibility="hidden"
        >
          <Center
            p="3px"
            borderRadius="5px"
            borderWidth="2px"
            borderColor="white"
            cursor="pointer"
            onClick={playAudio}
          >
            <Icon
              as={isPlayingAudio ? BsFillPauseFill : BsFillPlayFill}
              color="white"
              fontSize="18px"
            />
          </Center>
          <Text
            color="white"
            fontWeight="700"
            textAlign="center"
            fontSize="14px"
            mt="5px"
          >
            {item?.topmusic_name}
          </Text>

          <Text
            color="white"
            fontWeight="400"
            textAlign="center"
            fontSize="12px"
          >
            {item?.topmusic_artist}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default TopMusicas;
