import React from "react";

import { Flex, Icon, Text, HStack } from "@chakra-ui/react";
import { useSelector } from "react-redux";

import {
  FaTiktok,
  FaYoutube,
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaGooglePlay,
  FaApple,
} from "react-icons/fa";

import { ItemSocial } from "../Header";

function Footer() {
  const { config } = useSelector((store) => store.config);

  return (
    <Flex
      mx={["5%", "5%", "5%", "20%"]}
      mt="0px"
      mb={["55px", "55px", "55px", 0]}
      bg="white"
      borderTopRadius="5px"
      flexDir={["column", "column", "row"]}
    >
      <Flex bg="#a22e42" color="white" px="4%" py="15px" align="center">
        {/*<Icon
          as={FaApple}
          mr="10px"
          color="white"
          fontSize="28px"
          cursor="pointer"
          onClick={() => {
            window.open(config?.app_ios_link);
          }}
        />*/}
        <Icon
          as={FaGooglePlay}
          color="white"
          fontSize="21px"
          cursor="pointer"
          onClick={() => {
            window.open(config?.app_android_link);
          }}
        />
        <Text fontSize="15px" ml="15px" color="yellow" fontWeight="bold">
        DESCARGA NUESTRA APP
        </Text>
      </Flex>
      <Flex
        flex="1"
        bg="#dc3a56"
        px="20px"
        align="center"
        flexDir={["column", "column", "row"]}
        py="10px"
      >
        <Text color="white" flex="1">
          © 2023 - SPro Bolivia | Todos los derechos reservados.
          {/* © 2023 - <a target='_blank' href='https://sprobolivia.com'>SPro Bolivia</a> | Todos los derechos reservados. */}
        </Text>

        <HStack ml="15px" mt={["10px", "10px", 0]}>
          {config?.tiktok_type === "true" && (
            <ItemSocial
              url={config?.site_tiktok}
              CIcon={FaTiktok}
              color="white"
            />
          )}
          {config?.youtube_type === "true" && (
            <ItemSocial
              url={config?.site_youtube}
              CIcon={FaYoutube}
              color="white"
            />
          )}
          {config?.facebook_type === "true" && (
            <ItemSocial
              url={config?.site_facebook}
              CIcon={FaFacebook}
              color="white"
            />
          )}
          {config?.instagram_type === "true" && (
            <ItemSocial
              url={config?.site_instagram}
              CIcon={FaInstagram}
              color="white"
            />
          )}
          {config?.twitter_type === "true" && (
            <ItemSocial
              url={config?.site_twitter}
              CIcon={FaTwitter}
              color="white"
            />
          )}
        </HStack>
      </Flex>
    </Flex>
  );
}

export default Footer;
