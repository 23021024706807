import React from "react";

import {
  Flex,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";

import ListTab from "./TabList";

function Programas() {
  const { programas } = useSelector((store) => store.config);

  return (
    <Flex
      flex="1"
      flexDir="column"
      px={["5%", "5%", "20%"]}
      pb={["100%", "10%"]}
      mt={["20px", "0px"]}
      overflowY="auto"
    >
      <Flex
        mt={["5px", "20px"]}
        justify="space-between"
        alignItems="center"
        bg="#dc3a56"
        w="100%"
        py="20px"
        px="20px"
        borderTopRadius="5px"
      >
        <Text color="white" fontWeight="600">
          Programas
        </Text>
      </Flex>

      <Tabs
        variant="enclosed"
        mt="20px"
        align="center"
        colorScheme="blue"
        size={["sm", "md"]}
        defaultIndex={new Date().getDay()}
      >
        <TabList overflowX="auto" overflowY="hidden" bg="white">
          <Tab
            _selected={{ color: "white", bg: "#dc3a56" }}
            ml={["50px", "0px"]}
          >
            DOMINGO
          </Tab>
          <Tab _selected={{ color: "white", bg: "#dc3a56" }}>LUNES</Tab>
          <Tab _selected={{ color: "white", bg: "#dc3a56" }}>MARTES</Tab>
          <Tab _selected={{ color: "white", bg: "#dc3a56" }}>MIERCOLES</Tab>
          <Tab _selected={{ color: "white", bg: "#dc3a56" }}>JUEVES</Tab>
          <Tab _selected={{ color: "white", bg: "#dc3a56" }}>VIERNES</Tab>
          <Tab _selected={{ color: "white", bg: "#dc3a56" }}>SÁBADO</Tab>
        </TabList>
        <TabPanels>
          <TabPanel px="0px">
            <ListTab
              list={programas.filter((item) => item?.sunday_type == "true")}
            />
          </TabPanel>
          <TabPanel px="0px">
            <ListTab
              list={programas.filter((item) => item?.monday_type == "true")}
            />
          </TabPanel>
          <TabPanel px="0px">
            <ListTab
              list={programas.filter((item) => item?.tuesday_type == "true")}
            />
          </TabPanel>
          <TabPanel px="0px">
            <ListTab
              list={programas.filter((item) => item?.wednesday_type == "true")}
            />
          </TabPanel>
          <TabPanel px="0px">
            <ListTab
              list={programas.filter((item) => item?.thuersday_type == "true")}
            />
          </TabPanel>
          <TabPanel px="0px">
            <ListTab
              list={programas.filter((item) => item?.friday_type == "true")}
            />
          </TabPanel>
          <TabPanel px="0px">
            <ListTab
              list={programas.filter((item) => item?.saturday_type == "true")}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}

export default Programas;
