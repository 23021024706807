export const getDiaProgramacao = (programacao) => {
  switch (new Date().getDay()) {
    case 0:
      if (
        programacao.sunday_type == "true" ||
        programacao.sunday_tv_type == "true"
      ) {
        return true;
      }
      break;
    case 1:
      if (
        programacao.monday_type == "true" ||
        programacao.monday_tv_type == "true"
      ) {
        return true;
      }
      break;
    case 2:
      if (
        programacao.tuesday_type == "true" ||
        programacao.tuesday_tv_type == "true"
      ) {
        return true;
      }
      break;
    case 3:
      if (
        programacao.wednesday_type == "true" ||
        programacao.wednesday_tv_type == "true"
      ) {
        return true;
      }
      break;
    case 4:
      if (
        programacao.thuersday_type == "true" ||
        programacao.thuersday_tv_type == "true"
      ) {
        return true;
      }
      break;
    case 5:
      if (
        programacao.friday_type == "true" ||
        programacao.friday_tv_type == "true"
      ) {
        return true;
      }
      break;
    case 6:
      if (
        programacao.saturday_type == "true" ||
        programacao.saturday_tv_type == "true"
      ) {
        return true;
      }
      break;
  }
};

export const getDiaProgramacaoProximo = (programacao) => {
  switch (new Date().getDay() == 6 ? 0 : new Date().getDay() + 1) {
    case 0:
      if (programacao.sunday_type == "true") {
        return true;
      }
      break;
    case 1:
      if (programacao.monday_type == "true") {
        return true;
      }
      break;
    case 2:
      if (programacao.tuesday_type == "true") {
        return true;
      }
      break;
    case 3:
      if (programacao.wednesday_type == "true") {
        return true;
      }
      break;
    case 4:
      if (programacao.thuersday_type == "true") {
        return true;
      }
      break;
    case 5:
      if (programacao.friday_type == "true") {
        return true;
      }
      break;
    case 6:
      if (programacao.saturday_type == "true") {
        return true;
      }
      break;
  }
};
