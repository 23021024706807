import React, { forwardRef } from "react";

import {
  Select,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/react";

function CustomSelect(
  {
    label,
    containerStyle,
    titleInputStyle,
    list = [],
    listPropriedade = "nome",
    placeholder,
    value,
    error,
    renderItem = null,
    ...props
  },
  ref
) {
  return (
    <FormControl flexDir="column" {...containerStyle} isInvalid={!!error}>
      {label && (
        <FormLabel
          textAlign="left"
          mr="15px"
          fontWeight="500"
          color="blackAlpha.700"
          mb="5px"
          {...titleInputStyle}
        >
          {label}
        </FormLabel>
      )}
      <Select {...props} ref={ref} value={value}>
        {placeholder && <option value={null}>{placeholder}</option>}
        {list.map((item, index) => {
          if (renderItem) {
            return renderItem(item, index);
          }
          return (
            <option value={item?.id} key={item?.id}>
              {item[listPropriedade]}
            </option>
          );
        })}
      </Select>
      {!!error && <FormErrorMessage>{error?.message}</FormErrorMessage>}
    </FormControl>
  );
}

export default forwardRef(CustomSelect);
