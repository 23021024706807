export const BASE_URL = "https://panelpro.app/megasistemas";
export const BASE_URL_SLIDER_IMAGE = `${BASE_URL}/upload/slider/`;
export const BASE_URL_PROMOCAO_IMAGE = `${BASE_URL}/upload/promotions/`;
export const BASE_URL_TOP_MUSICA_IMAGE = `${BASE_URL}/upload/topmusic/image/`;
export const BASE_URL_TOP_MUSICA_AUDIO = `${BASE_URL}/upload/topmusic/`;
export const BASE_URL_PODCAST_IMAGE = `${BASE_URL}/upload/podcast/`;
export const BASE_URL_PODCAST_AUDIO = `${BASE_URL}/upload/music/`;
export const BASE_URL_NEWS_IMAGE = `${BASE_URL}/upload/news/`;
export const BASE_URL_PROGRAMS_IMAGE = `${BASE_URL}/upload/program/`;
export const BASE_URL_LOGO = `${BASE_URL}/upload/website/`;
export const BASE_URL_BACKGROUND = `${BASE_URL}/upload/website/background/`;
export const BASE_URL_LOCUTOR = `${BASE_URL}/upload/announcer/`;
export const BASE_IMAGE_PROGRAMACAO = `${BASE_URL}/upload/announcer/`;
