import React from "react";

import { Flex, Textarea as CustomTextarea, Text } from "@chakra-ui/react";

function Textarea({ label, ...props }) {
  return (
    <Flex flexDir="column">
      {label && <Text>{label}</Text>}
      <CustomTextarea {...props} />
    </Flex>
  );
}

export default Textarea;
