import React from "react";

import { Flex, Text, Image, Stack, StackDivider } from "@chakra-ui/react";

import { BASE_URL_LOCUTOR } from "../../utils/config";

function TabList({ list = [] }) {
  return (
    <Stack
      flexDir="column"
      bg="white"
      p={["5px", "15px"]}
      borderRadius="5px"
      divider={<StackDivider borderColor="gray.200" />}
    >
      {list.map((item) => (
        <Flex key={`programa-${item?.id}`}>
          <Image
            src={`${BASE_URL_LOCUTOR}${item?.announcer_image}`}
            w="50px"
            h="50px"
            borderRadius="5px"
          />
          <Flex flexDir="column" ml="20px">
            <Text fontWeight="600" fontSize="14px">
              {item?.program_name}
            </Text>
            <Text fontSize="12px">
              {item?.program_hour_start} às {item?.program_hour_end}
            </Text>
          </Flex>
        </Flex>
      ))}
    </Stack>
  );
}

export default TabList;
