import React from "react";

import { Flex, Text, Image, IconButton, Icon } from "@chakra-ui/react";

import { BsPlay, BsPause, BsDownload } from "react-icons/bs";
import { saveAs } from "file-saver";
import {
  BASE_URL_PODCAST_IMAGE,
  BASE_URL_PODCAST_AUDIO,
  BASE_URL,
} from "../../../utils/config";
import { useAudio } from "../../../contexts/Audio";

function ItemList({ item }) {
  const { play, isPlaying } = useAudio();

  const fileUrl = `${BASE_URL_PODCAST_AUDIO}${item?.podcast_file}`;

  const isPlayingAudio = isPlaying(fileUrl);
  const downloadFile = () => {
    let folder = BASE_URL_PODCAST_AUDIO.replace(BASE_URL, "").substring(1);
    folder = folder.substring(0, folder.length - 1);
    const file = item?.podcast_file;
    saveAs(
      `${BASE_URL}/downloadAudio.php?pasta=${folder}&arquivo=${file}`,
      file
    );
  };

  const playAudio = () => {
    if (isPlayingAudio) {
      play();
    } else {
      play(fileUrl);
    }
  };

  return (
    <Flex
      bg="white"
      shadow="md"
      borderRadius="5px"
      h="80px"
      _hover={{
        boxShadow: "lg",
      }}
    >
      <Image
        borderRadius="5px"
        src={`${BASE_URL_PODCAST_IMAGE}${item?.podcast_image}`}
        alt={item?.podcast_name}
        h="80px"
        w="80px"
      />
      <Flex
        flexDir="column"
        mx="10px"
        justify="space-between"
        flex="1"
        py="10px"
      >
        <Text fontSize="14px">{item?.podcast_name}</Text>
        <Text fontSize="12px" noOfLines={2} color="blackAlpha.600">
          {item?.podcast_name_des}
        </Text>

        <Flex justify="space-between" align="flex-end">
          <Text fontSize="10px" color="blackAlpha.600">
            {item?.podcast_date}
          </Text>
          <Flex
            alignSelf="flex-end"
            flex="1"
            align="flex-end"
            justify="flex-end"
          >
            <IconButton
              size="xs"
              aria-label="play"
              icon={<Icon as={isPlayingAudio ? BsPause : BsPlay} />}
              onClick={playAudio}
            />
            <IconButton
              ml="10px"
              size="xs"
              aria-label="download"
              icon={<Icon as={BsDownload} />}
              onClick={downloadFile}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default ItemList;
