import React from "react";
import { Flex } from "@chakra-ui/react";

import {
  Sliders,
  Promocoes,
  TopMusicas,
  PromocoesGanhadores,
  Podcasts,
  Noticias,
  Videos,
} from "./components";

function Home() {
  return (
    <Flex
      flex="1"
      flexDir="column"
      px={["5%", "5%", "5%", "20%"]}
      overflowY="auto"
      pb={["100%", "10%"]}
      mt={["20px", "20px", "20px", "0px"]}
    >
      <Sliders />
      {/* <Flex mt="20px" flexDir={["column", "column", "row"]}>
        <Promocoes flex="6" mr={["0px", "0px", "5%"]} />
        <PromocoesGanhadores flex="4" mt={["10px", "10px", "0px"]} />
      </Flex>*/}

      <Flex mt="20px" flex="1" flexDir={["column", "column", "row"]}>
        <Noticias flex="6" mr={["0px", "0px", "5%"]} />
        <Podcasts flex="4" mt={["10px", "10px", "0px"]} />
      </Flex>

      <TopMusicas mt="20px" flex="1" />
      <Videos mt="20px" flex="1" />
    </Flex>
  );
}

export default Home;
