import React, { useEffect, useRef } from "react";

import { Flex, useDimensions } from "@chakra-ui/react";

import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactPlayer from "react-player";

function Detalhes() {
  const history = useHistory();

  const { video } = useSelector((store) => store.config);

  useEffect(() => {
    if (!video) {
      history.goBack();
    }
  }, []);

  const elementRef = useRef();
  const dimensions = useDimensions(elementRef, true);

  return (
    <Flex
      bg="white"
      borderRadius="5px"
      mx={["2%", "5%", "20%"]}
      mt="20px"
      flexDir="column"
      p={["10px", "20px"]}
      minH="450px"
    >
      <ReactPlayer
        playing
        url={video?.video_url}
        height={(dimensions && dimensions?.borderBox?.height - 40) || 450}
        width={dimensions && dimensions?.borderBox?.width - 40}
      />
    </Flex>
  );
}

export default Detalhes;
