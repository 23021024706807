import React, { useState, useEffect } from "react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Stack,
} from "@chakra-ui/react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import { Input, Select } from "../index";
import { BASE_URL } from "../../utils/config";
import { setUser } from "../../store/modules/user/actions";

function ModalAuth({ isOpen, onClose = () => {} }) {
  const dispatch = useDispatch();
  const { user } = useSelector((store) => store.user);

  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [sexo, setSexo] = useState("Masculino");
  const [telefone, setTelefone] = useState("");
  const [dataNascimento, setDataNascimento] = useState("");
  const [cidade, setCidade] = useState("");
  const [pais, setPais] = useState("");
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setNome(user?.users_name||'');
    setEmail(user?.users_email||'');
    setSexo(user?.users_sex||'');
    setTelefone(user?.users_phone||'');
    setDataNascimento(formata_data(user?.users_birthday)||'');
    setCidade(user?.users_city||'');
    setPais(user?.users_country||'');
    setLoading(false);
  }, []);

  function formata_data(data) {
    try {
      let data_formatada =
        data.substr(6, 4) + "-" + data.substr(3, 2) + "-" + data.substr(0, 2);
      return data_formatada;
    } catch (error) {
      return data;
    }
  }

  const validationForm = () => {
    if (!nome || nome.trim() === "") return true;
    if (!email|| email.trim() === "") return true;
    if (!telefone || telefone.trim() === "") return true;
    if (!dataNascimento) return true;
    if (!cidade || cidade.trim() === "") return true;
    if (!pais || pais.trim() === "") return true;
    return false;
  };

  const handleRegister = () => {
    setLoading(true);
    const data = new FormData();
    data.append("editar_perfil", true);
    data.append("cid", user?.cid);
    data.append("users_name", nome);
    data.append("users_email", email);
    data.append("users_sex", sexo);
    data.append("users_phone", telefone);
    data.append("users_birthday", dataNascimento);
    data.append("users_country", pais);
    data.append("users_city", cidade);

    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    };
    axios
      .post(`${BASE_URL}/ApiRadio.php`, data, config)
      .then((resp) => {
        const data = resp?.data;
        if (data?.error) {
          alert(data?.message);
        } else {
          dispatch(setUser(data));
          onClose();
          alert("Perfil atualizado com sucesso.");
        }
      })
      .catch(() => {
        alert(
          "Ocorreu um error ao processar sua solicitação, tente novamente mais tarde."
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Perfil</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack>
            <Input
              label="Nome"
              value={nome}
              onChange={(e) => setNome(e?.target?.value)}
            />
            <Input
              label="Email"
              value={email}
              onChange={(e) => setEmail(e?.target?.value)}
            />
            <Select
              label="Sexo"
              value={sexo}
              onChange={(e) => setSexo(e?.target?.value)}
              listPropriedade="id"
              list={[{ id: "Masculino" }, { id: "Feminino" }]}
            />
            <Input
              label="Telefone"
              value={telefone}
              onChange={(e) => setTelefone(e?.target?.value)}
            />
            <Input
              label="Data de nascimento"
              type="date"
              value={dataNascimento}
              onChange={(e) => setDataNascimento(e?.target?.value)}
            />
            <Input
              label="Cidade"
              value={cidade}
              onChange={(e) => setCidade(e?.target?.value)}
            />
            <Input
              label="Pais"
              value={pais}
              onChange={(e) => setPais(e?.target?.value)}
            />
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" size="sm">
            Cancelar
          </Button>
          <Button
            colorScheme="blue"
            ml={3}
            size="sm"
            onClick={handleRegister}
            isDisabled={validationForm()}
            isLoading={isLoading}
          >
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ModalAuth;
