import React, { useEffect } from "react";

import { Flex, Image, Text } from "@chakra-ui/react";

import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { BASE_URL_NEWS_IMAGE } from "../../utils/config";

function Detalhes() {
  const history = useHistory();

  const { noticia } = useSelector((store) => store.config);

  useEffect(() => {
    if (!noticia) {
      history.goBack();
    }
  }, []);

  return (
    <Flex
      bg="white"
      borderRadius="5px"
      mx={["2%", "5%", "20%"]}
      mt="20px"
      flexDir="column"
      p={["10px", "20px"]}
      minH="450px"
    >
      <Image
        src={`${BASE_URL_NEWS_IMAGE}${noticia?.news_image}`}
        w="100%"
        h="100%"
        borderRadius="5px"
      />

      <Text mt="10px" fontWeight="600" fontSize="18px">
        {noticia?.news_name}
      </Text>

      <Flex my="20px" fontSize="14px">
        <div dangerouslySetInnerHTML={{ __html: noticia?.news_post }} />
      </Flex>
    </Flex>
  );
}

export default Detalhes;
