import React from "react";
import { useSelector } from "react-redux";

import { Flex, Stack, Text, Icon } from "@chakra-ui/react";
import { BsMusicNote } from "react-icons/bs";
import { Link } from "react-router-dom";

import { ItemList } from "../../Podcasts/components";

function Podcasts(props) {
  const { podcasts = [] } = useSelector((store) => store.config);

  return (
    <Flex flexDir="column" {...props}>
      <Flex
        justify="space-between"
        alignItems="center"
        bg="#dc3a56"
        w="100%"
        py="10px"
        px="20px"
        borderTopRadius="5px"
      >
        <Flex align="center">
          <Icon as={BsMusicNote} color="white" fontSize="35px" />
          <Text color="white" fontWeight="600" ml="10px"><b>
            PODCAST
          </b>
          </Text>
        </Flex>
        <Text color="white" fontWeight="extrabold" as={Link} to="/podcasts">
          VER MAS
        </Text>
      </Flex>
      <Stack spacing="10px" bg="white" p="0px" mt="10px" borderRadius="5px">
        {podcasts.slice(0, 9).map((item) => (
          <ItemList item={item} key={`podcasts-${item?.cid}`} />
        ))}
      </Stack>
    </Flex>
  );
}

export default Podcasts;
