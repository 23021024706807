import React, { useState, useEffect } from "react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Stack,
  Flex,
} from "@chakra-ui/react";
import axios from "axios";
import { useDispatch } from "react-redux";

import ModalRegister from "./Register";
import ModalRecoveryPassword from "./RecoveryPassword";

import { Input } from "../index";
import { BASE_URL } from "../../utils/config";
import { setUser } from "../../store/modules/user/actions";

function ModalAuth({ isOpen, onClose = () => {} }) {
  const dispatch = useDispatch();

  const [isOpenModalRegister, setOpenModalRegister] = useState(false);
  const [isOpenModalRecoveryPassowrd, setOpenModalRecoveryPassowrd] =
    useState(false);

  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setOpenModalRegister(false);
    setEmail("");
    setSenha("");
    setLoading(false);
  }, [isOpen]);

  const handleLogin = () => {
    setLoading(true);
    const data = new FormData();
    data.append("login", true);
    data.append("email", email);
    data.append("senha", senha);
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    };
    axios
      .post(`${BASE_URL}/ApiRadio.php`, data, config)
      .then((resp) => {
        const data = resp?.data;
        if (data?.error) {
          alert(data?.message);
        } else {
          dispatch(setUser(data));
          alert("Inicio de sesión correcto.");
          onClose();
        }
      })
      .catch(() => {
        alert(
          "Hubo un error al procesar su solicitud, inténtelo de nuevo más tarde."
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Iniciar Sesión</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack>
            <Input
              label="Correo Electrónico"
              value={email}
              onChange={(e) => setEmail(e?.target?.value)}
            />
            <Input
              label="Contraseña"
              value={senha}
              onChange={(e) => setSenha(e?.target?.value)}
            />
            <Flex justify="space-between">
              <Button
                variant="link"
                size="xs"
                mr="3"
                onClick={() => setOpenModalRegister(true)}
              >
                Crea cuenta
              </Button>
              <Button
                variant="link"
                size="xs"
                ml="3"
                onClick={() => setOpenModalRecoveryPassowrd(true)}
              >
                Recuperar contraseña
              </Button>
            </Flex>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" size="sm">
            Cancelar
          </Button>
          <Button
            colorScheme="blue"
            ml={3}
            size="sm"
            onClick={handleLogin}
            isLoading={isLoading}
          >
            Entrar
          </Button>
        </ModalFooter>
      </ModalContent>
      <ModalRegister
        isOpen={isOpenModalRegister}
        onClose={() => setOpenModalRegister(false)}
        onCloseAll={onClose}
      />
      <ModalRecoveryPassword
        isOpen={isOpenModalRecoveryPassowrd}
        onClose={() => setOpenModalRecoveryPassowrd(false)}
      />
    </Modal>
  );
}

export default ModalAuth;
