import React from "react";

import { Flex, Text, SimpleGrid } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { ItemList } from "./components";
import { setNoticia } from "../../store/modules/config/actions";

function Noticias() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { noticias } = useSelector((store) => store.config);

  const handleClickItem = (item) => {
    dispatch(setNoticia(item));
    history.push("noticias/detalhes");
  };

  return (
    <Flex
      flex="1"
      flexDir="column"
      px={["5%", "5%", "20%"]}
      pb={["100%", "10%"]}
      mt={["20px", "0px"]}
      overflowY="auto"
    >
      <Flex
        mt={["5px", "20px"]}
        justify="space-between"
        alignItems="center"
        bg="#dc3a56"
        w="100%"
        py="20px"
        px="20px"
        borderTopRadius="5px"
      >
        <Text color="white" fontWeight="600">
          Noticias
        </Text>
      </Flex>

      <SimpleGrid mt="20px" columns={2} spacing="20px" minChildWidth="230px">
        {noticias.map((item) => (
          <ItemList
            item={item}
            key={`noticias-${item?.id}`}
            onClick={() => handleClickItem(item)}
          />
        ))}
      </SimpleGrid>
    </Flex>
  );
}

export default Noticias;
