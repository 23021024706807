import React from "react";

import { Switch, Route } from "react-router-dom";

import Home from "../pages/Home";
import Contato from "../pages/Contato";
import Noticias from "../pages/Noticias";
import NoticiaDetalhes from "../pages/Noticias/Detalhes";
import Programas from "../pages/Programas";
import Promocoes from "../pages/Promocoes";
import PromocaoDetalhes from "../pages/Promocoes/Detalhes";
import Podcasts from "../pages/Podcasts";
import Videos from "../pages/Videos";
import VideoDetalhes from "../pages/Videos/Detalhes";

function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>

      <Route exact path="/home">
        <Home />
      </Route>

      <Route exact path="/promocoes">
        <Promocoes />
      </Route>

      <Route exact path="/promocoes/detalhes">
        <PromocaoDetalhes />
      </Route>

      <Route exact path="/contato">
        <Contato />
      </Route>

      <Route exact path="/noticias">
        <Noticias />
      </Route>

      <Route exact path="/noticias/detalhes">
        <NoticiaDetalhes />
      </Route>

      <Route exact path="/programas">
        <Programas />
      </Route>

      <Route exact path="/podcasts">
        <Podcasts />
      </Route>

      <Route exact path="/videos">
        <Videos />
      </Route>

      <Route exact path="/videos/player">
        <VideoDetalhes />
      </Route>

      <Route exact path="/*">
        <Home />
      </Route>
    </Switch>
  );
}

export default Routes;
