import React from "react";

import { Flex, Text, Image, SimpleGrid, Icon } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { BsNewspaper } from "react-icons/bs";

import { ItemList } from "../../Noticias/components";
import { setNoticia } from "../../../store/modules/config/actions";
import { BASE_URL_NEWS_IMAGE } from "../../../utils/config";

function Noticias(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const { noticias } = useSelector((store) => store.config);

  const handleClickItem = (item) => {
    dispatch(setNoticia(item));
    history.push("noticias/detalhes");
  };

  return (
    <Flex flexDir="column" {...props}>
      <Flex
        justify="space-between"
        alignItems="center"
        bg="#dc3a56"
        w="100%"
        py="10px"
        px="20px"
        borderTopRadius="5px"
      >
        <Flex align="center">
          <Icon as={BsNewspaper} color="white" fontSize="35px" />
          <Text color="white" fontWeight="600" ml="10px"><b>
            ULTIMAS NOTICIAS
          </b>
          </Text>
        </Flex>
        <Text color="white" fontWeight="extrabold" as={Link} to="/noticias">
          VER MAS
        </Text>
      </Flex>

      <Flex flexDir="column" mt="10px">
        {noticias.length > 0 && (
          <ItemMainNoticia
            item={noticias[0]}
            onClick={() => handleClickItem(noticias[0])}
          />
        )}
        <SimpleGrid
          mt="10px"
          columns={2}
          spacing="20px"
          minChildWidth="300px"
          justifyContent="space-between"
        >
          {noticias.slice(1, 7).map((item) => (
            <ItemList
              item={item}
              key={`noticias-${item?.id}`}
              onClick={() => handleClickItem(item)}
            />
          ))}
        </SimpleGrid>
      </Flex>
    </Flex>
  );
}

const ItemMainNoticia = ({ item, onClick = () => {} }) => (
  <Flex pos="relative" onClick={onClick} cursor="pointer">
    <Image
      borderRadius="5px"
      src={`${BASE_URL_NEWS_IMAGE}${item?.news_image}`}
      w={"100%"}
      h={["200px", "100%"]}
    />
    <Flex pos="absolute" bottom="0" bg="rgba(0,0,0,0.5)" py="10px" px="10px">
      <Text color="white" fontWeight="600" noOfLines={2}>
        {item?.news_name}
      </Text>
    </Flex>
  </Flex>
);

export default Noticias;
