export function setProgramas(programas) {
  return {
    type: "@config/SET_PROGRAMAS",
    payload: { programas },
  };
}

export function setNoticias(noticias) {
  return {
    type: "@config/SET_NOTICIAS",
    payload: { noticias },
  };
}

export function setNoticia(noticia) {
  return {
    type: "@config/SET_NOTICIA",
    payload: { noticia },
  };
}

export function setVideos(videos) {
  return {
    type: "@config/SET_VIDEOS",
    payload: { videos },
  };
}

export function setVideo(video) {
  return {
    type: "@config/SET_VIDEO",
    payload: { video },
  };
}

export function setPodcasts(podcasts) {
  return {
    type: "@config/SET_PODCASTS",
    payload: { podcasts },
  };
}

export function setTopMusicas(top_musicas) {
  return {
    type: "@config/SET_TOP_MUSICAS",
    payload: { top_musicas },
  };
}

export function setPromocoes(promocoes) {
  return {
    type: "@config/SET_PROMOCOES",
    payload: { promocoes },
  };
}

export function setPromocao(promocao) {
  return {
    type: "@config/SET_PROMOCAO",
    payload: { promocao },
  };
}

export function setSliders(sliders) {
  return {
    type: "@config/SET_SLIDERS",
    payload: { sliders },
  };
}

export function setRadio(radio) {
  return {
    type: "@config/SET_RADIO",
    payload: { radio },
  };
}

export function setPromocoesGanhadores(list) {
  return {
    type: "@config/SET_PROMOCOES_GANHADORES",
    payload: { list },
  };
}

export function setConfigSite(config) {
  return {
    type: "@config/SET_CONFIG",
    payload: { config },
  };
}
