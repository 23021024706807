export function setUser(user) {
  return {
    type: "@user/SET_USER",
    payload: { user },
  };
}

export function logout() {
  return {
    type: "@user/LOGOUT",
  };
}
