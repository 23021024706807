import React from "react";

import { Flex, Text, SimpleGrid } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { ItemList } from "./components";
import { setVideo } from "../../store/modules/config/actions";

function Videos() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { videos } = useSelector((store) => store.config);

  const handleClickItem = (item) => {
    dispatch(setVideo(item));
    history.push("videos/player");
  };

  return (
    <Flex
      flex="1"
      flexDir="column"
      px={["5%", "5%", "20%"]}
      pb={["100%", "10%"]}
      mt={["20px", "0px"]}
      overflowY="auto"
    >
      <Flex
        mt={["5px", "20px"]}
        justify="space-between"
        alignItems="center"
        bg="#dc3a56"
        w="100%"
        py="20px"
        px="20px"
        borderTopRadius="5px"
      >
        <Text color="white" fontWeight="600">
          Vídeos
        </Text>
      </Flex>

      <Flex flexWrap="wrap" mt="20px">
        {videos.map((item) => (
          <ItemList
            item={item}
            key={`videos-${item?.id}`}
            onClick={() => handleClickItem(item)}
            m="10px"
          />
        ))}
      </Flex>
    </Flex>
  );
}

export default Videos;
