import React from "react";

import { Flex, Text, Icon } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { MdVideoLibrary } from "react-icons/md";

import { ItemList } from "../../Videos/components";
import { setVideo } from "../../../store/modules/config/actions";

function Videos(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const { videos } = useSelector((store) => store.config);

  const handleClickItem = (item) => {
    dispatch(setVideo(item));
    history.push("videos/player");
  };

  return (
    <Flex flexDir="column" {...props}>
      <Flex
        justify="space-between"
        alignItems="center"
        bg="#dc3a56"
        w="100%"
        py="10px"
        px="20px"
        borderTopRadius="5px"
      >
        <Flex align="center">
          <Icon as={MdVideoLibrary} color="white" fontSize="35px" />
          <Text color="white" fontWeight="600" ml="10px"><b>
            VÍDEOS
          </b></Text>
        </Flex>
        <Text color="white" fontWeight="extrabold" as={Link} to="/videos">
          VER MAS
        </Text>
      </Flex>

      <Flex mt="10px" maxH="300px" flexDir={["column", "column", "row"]}>
        {videos.slice(0, 1).map((item) => (
          <ItemList
            w={["100%", "100%", "70%"]}
            h={"310px"}
            mb="10px"
            item={item}
            key={`videos-${item?.id}`}
            onClick={() => handleClickItem(item)}
            objectFit="cover"
          />
        ))}
        <Flex flexDir="column" ml={["0px", "0px", "20px"]} flex="3">
          {videos.slice(1, 3).map((item) => (
            <ItemList
              objectFit="fill"
              mb="10px"
              w="100%"
              h="150px"
              flex="1"
              item={item}
              key={`videos-${item?.id}`}
              onClick={() => handleClickItem(item)}
            />
          ))}
        </Flex>
      </Flex>
      {/* <SimpleGrid mt="20px" columns={3} spacing="20px" minChildWidth="300px">
        {videos.slice(0, 3).map((item, index) => (
          <ItemList
            w={index == 0 ? "400px" : "250px"}
            item={item}
            key={`videos-${item?.id}`}
            onClick={() => handleClickItem(item)}
          />
        ))}
      </SimpleGrid> */}
    </Flex>
  );
}

export default Videos;
