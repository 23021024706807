import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { Flex, Spinner } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { AudioContext } from "./contexts/Audio";

import { Header, Player } from "./components";
import Routes from "./routes";
import {
  setNoticias,
  setPodcasts,
  setPromocoes,
  setSliders,
  setTopMusicas,
  setRadio,
  setVideos,
  setProgramas,
  setPromocoesGanhadores,
  setConfigSite,
} from "./store/modules/config/actions";

import { BASE_URL, BASE_URL_BACKGROUND } from "./utils/config";
import { Footer } from "./components";

function App() {
  const dispatch = useDispatch();
  const { config } = useSelector((store) => store.config);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    loadAllConfig();
  }, []);

  const loadAllConfig = () => {
    axios
      .get(`${BASE_URL}/ApiRadio.php?get_all=true`)
      .then((resp) => {
        const data = resp.data;
        console.log("data", data);
        dispatch(setRadio(data?.radio));
        dispatch(setPodcasts(data?.podcasts));
        dispatch(setPromocoes(data?.promocoes));
        dispatch(setNoticias(data?.noticias));
        dispatch(setSliders(data?.sliders));
        dispatch(setTopMusicas(data?.top_musicas));
        dispatch(setVideos(data?.videos));
        dispatch(setProgramas(data?.programas));
        dispatch(setPromocoesGanhadores(data?.promocoes_ganhadores));
        let config = {};
        if (data?.site && (data?.site || []).length > 0) {
          config = data.site[0];
        }
        if (data?.config && (data?.config || []).length > 0) {
          config = { ...config, ...data.config[0] };
        }
        dispatch(setConfigSite(config));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (isLoading) {
    return (
      <Flex
        flexDir="column"
        flex="1"
        minH="100vh"
        bg="gray.100"
        align="center"
        justify="center"
      >
        <Spinner speed="0.65s" size="lg" />
      </Flex>
    );
  }

  return (
    <BrowserRouter>
      <AudioContext>
        <Player>
          <Flex
            flexDir="column"
            flex="1"
            minH="100vh"
            bg={
              config?.site_background
                ? `no-repeat url(${BASE_URL_BACKGROUND}${config?.site_background})`
                : "gray.100"
            }
            bgSize="cover"
          >
            <Header />
            <Flex flexDir="column">
              <Routes />
              <Footer />
            </Flex>
          </Flex>
        </Player>
      </AudioContext>
    </BrowserRouter>
  );
}

export default App;
