import React, { useEffect, useState } from "react";

import { Flex, Image, Button, Text } from "@chakra-ui/react";

import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";

import { BASE_URL_PROMOCAO_IMAGE } from "../../utils/config";
import { BASE_URL } from "../../utils/config";
import { ModalAuth } from "../../components";

function Detalhes() {
  const history = useHistory();

  const { promocao } = useSelector((store) => store.config);
  const { user } = useSelector((store) => store.user);

  const [isLoading, setLoading] = useState(false);
  const [isOpenModalAuth, setOpenModalAuth] = useState(false);

  useEffect(() => {
    if (!promocao) {
      history.goBack();
    }
  }, []);

  const handleRequest = () => {
    if (!user) {
      return setOpenModalAuth(true);
    }

    setLoading(true);
    const data = new FormData();
    data.append("promocao", true);
    data.append("users_app_id", user?.cid);
    data.append("promotions_id", promocao?.id);

    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    };
    axios
      .post(`${BASE_URL}/ApiRadio.php`, data, config)
      .then((resp) => {
        const data = resp?.data;
        alert(data?.message);
      })
      .catch(() => {
        alert(
          "Ocorreu um error ao processar sua solicitação, tente novamente mais tarde."
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Flex
      bg="white"
      borderRadius="5px"
      mx={["2%", "5%", "20%"]}
      mt="20px"
      flexDir="column"
      p={["10px", "20px"]}
      minH="450px"
    >
      <Image
        src={`${BASE_URL_PROMOCAO_IMAGE}${promocao?.promotions_image}`}
        w="100%"
        h="300px"
        borderRadius="5px"
      />
      <Text mt="10px" fontWeight="600" fontSize="18px">
        {promocao?.promotions_name}
      </Text>

      <Flex my="20px" fontSize="14px">
        <div
          dangerouslySetInnerHTML={{ __html: promocao?.promotions_description }}
        />
      </Flex>

      <Button
        mt="20px"
        colorScheme="blue"
        onClick={handleRequest}
        isLoading={isLoading}
      >
        PARTICIPAR
      </Button>
      <ModalAuth
        isOpen={isOpenModalAuth}
        onClose={() => setOpenModalAuth(false)}
      />
    </Flex>
  );
}

export default Detalhes;
